import React from 'react';

import { Loader } from 'rsuite';

const Fallback: React.FC = () => {
  return (
    <>
      <Loader />
    </>
  );
}

export default Fallback;